<!--
 The LayoutDkTeaserRow component takes 3 articles and displays them in a one row view.
 Desktop: only secondary teasers in one row.
 Mobile: only secondary teasers in one column.
-->
<template>
  <div
    v-if="props.articles?.length > 0"
    class="grid grid-cols-2 md:grid-cols-9 gap-y-6 md:gap-x-8"
  >
    <div v-for="(article, index) in props.articles" class="col-span-3">
      <TeaserSecondaryPlacement
        :article="article"
        :index="index"
        :same-size="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'

const props = withDefaults(
  defineProps<{
    articles: ContentArticle[]
    lazy?: boolean
    preloadFirstImage?: boolean
  }>(),
  {
    lazy: true,
    preloadFirstImage: false,
  }
)
</script>
