<template>
  <template v-if="props.isFirstArticle">
    <figure
      v-if="props.articleImage"
      :class="{
        relative: props.isVideo,
      }"
    >
      <NuxtImg
        :src="props.articleImage"
        class="w-full"
        :class="{
          'rounded-xl': props.isFramed,
        }"
        :alt="props.articleTitle"
        :width="968"
        :height="544"
        :loading="props.lazy ? 'lazy' : 'eager'"
        :preload="props.preload"
      />
      <!-- If article type is video, rendes play button in center of image  -->
      <div
        v-if="props.isVideo"
        class="rounded-full cursor-pointer bg-red h-15 w-15 flex items-center justify-center absolute top-1/2 left-1/2 z-10 transform -translate-x-1/2 -translate-y-1/2"
      >
        <img
          class="w-6 h-6 transform ml-3px inline-block"
          src="@/assets/icons/play.svg?url"
          :alt="$t('Play')"
        />
      </div>
    </figure>
    <img
      v-else
      class="w-full"
      :class="{
        'rounded-xl': props.isFramed,
      }"
      src="@/assets/images/article-placeholder.png"
      :alt="props.articleTitle"
      :width="968"
      :height="544"
    />
  </template>
  <template v-else>
    <div
      v-if="props.articleImage"
      class="flex w-28 h-16 grow-0 shrink-0 items-center justify-center"
      :class="{
        relative: props.isVideo,
        'md:w-auto md:h-auto': !props.rowLayout,
      }"
    >
      <NuxtImg
        quality="80"
        :loading="lazy ? 'lazy' : 'eager'"
        :preload="preload"
        :alt="props.articleTitle"
        :src="props.articleImage"
        :width="460"
        :height="260"
        class="w-full h-auto image-loader rounded"
        :class="{
          'md:rounded-none': !props.rowLayout,
        }"
      />
      <div
        v-if="props.isVideo"
        class="rounded-full cursor-pointer bg-red h-6 w-6 md:h-10 md:w-10 flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <img
          class="w-3 h-3 md:w-5 md:h-5 transform ml-2px inline-block"
          src="@/assets/icons/play.svg?url"
          :alt="$t('Play')"
        />
      </div>
    </div>

    <div
      v-else
      class="overflow-hidden rounded flex w-28 h-16 grow-0 shrink-0 items-center justify-cente"
      :class="{
        'md:rounded-none md:w-auto md:h-auto': !props.rowLayout,
      }"
    >
      <img
        :width="460"
        :height="260"
        class="w-full h-auto"
        :class="{
          'border border-gray-200': !props.isFramed,
        }"
        src="@/assets/images/article-placeholder.png"
        :alt="props.articleTitle"
      />
    </div>
  </template>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    articleImage: string
    articleTitle: string
    isFirstArticle?: boolean // Displays the image in card view: big image and title under it. Default: false. Teaser view: small image and title on the right side.
    isFramed?: boolean // Add styles for framed article, that used in Theme/Featured articles.
    isVideo?: boolean
    lazy?: boolean
    preload?: boolean
    rowLayout?: boolean
  }>(),
  {
    isFirstArticle: false,
    isFramed: false,
    isVideo: false,
    lazy: true,
    preload: false,
    rowLayout: false,
  }
)
</script>
