<template>
  <NuxtImg
    quality="80"
    v-if="props.imageUrl"
    :src="props.imageUrl"
    :alt="props.alt"
    :width="width"
    :height="height"
    :loading="props.loadingMode"
    :preload="props.preload"
  />

  <img
    v-else
    src="@/assets/images/article-placeholder.png"
    alt=""
    :width="width"
    :height="height"
    loading="lazy"
  />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    imageUrl?: string
    alt?: string
    width?: number
    height?: number
    loadingMode?: 'lazy' | 'eager'
    preload?: boolean
  }>(),
  {
    imageUrl: undefined,
    alt: '',
    loadingMode: 'lazy',
    preload: false,
    width: 968,
    height: 544,
  }
)
</script>
