<template>
  <Transition name="skeleton" mode="out-in">
    <div v-if="loaded" key="B2Ccontent">
      <!-- First article in a list -->
      <template v-if="props.index === 0 && !props.sameSize">
        <B2CArticleInlineTeaser
          :class="{
            'flex md:hidden': props.mobileSmall,
            hidden: !props.mobileSmall,
          }"
          :article="props.article"
          :article-image="articleImage"
          :article-title="articleTitle"
          :type="type"
          :is-video="isVideo"
          :preload="props.preload"
          :lazy="props.lazy"
        />

        <NuxtLink
          :to="articleRouteObject"
          class="relative flex flex-col bg-[#2C2CA40D] rounded-lg md:rounded-xl overflow-hidden group"
          :class="{
            'hidden md:flex': props.mobileSmall,
          }"
        >
          <B2CArticleTeaserImage
            :article-image="articleImage"
            :article-title="articleTitle"
            is-first-article
            :is-video="isVideo"
            :preload="props.preload"
            :lazy="props.lazy"
          />
          <div
            class="w-full md:w-9/10 p-4 md:p-8 rounded-xl rounded-t-none border border-t-0 border-gray-300"
          >
            <B2CArticleTeaserLabel
              class="mb-1 md:mb-3"
              :type="type"
              :article="props.article"
              :is-first-article="true"
              :is-video="isVideo"
            />

            <h2
              class="text-xl/6 md:text-5xl/[56px] font-bold font-sans text-black -tracking-[0.005em] group-hover:underline decoration-2 text-pretty"
            >
              {{ articleTitle }}
            </h2>
          </div>
        </NuxtLink>
      </template>
      <!-- Not first article in a list -->
      <template v-else>
        <B2CArticleInlineTeaser
          :article="props.article"
          :article-image="articleImage"
          :article-title="articleTitle"
          :type="type"
          :is-video="isVideo"
          :preload="props.preload"
          :lazy="props.lazy"
        />
      </template>
    </div>

    <ContentLoader
      v-else
      key="loader"
      :height="props.index === 0 ? 320 : 60"
      :primary-color="indexStore.skeleton.primaryColor"
      :secondary-color="indexStore.skeleton.secondaryColor"
      :style="{ marginBottom: '16px' }"
    >
      <template v-if="props.index === 0">
        <rect x="0" y="0" rx="3" ry="3" width="400" height="235" />
        <rect x="0" y="250" rx="3" ry="3" width="410" height="16" />
        <rect x="0" y="270" rx="3" ry="3" width="380" height="16" />
        <rect
          class="hidden md:block"
          x="0"
          y="295"
          rx="3"
          ry="3"
          width="380"
          height="6"
        />
        <rect
          class="hidden md:block"
          x="0"
          y="305"
          rx="3"
          ry="3"
          width="200"
          height="6"
        />
      </template>
      <template v-else>
        <!-- Image placeholder on the left -->
        <rect x="0" y="0" rx="3" ry="3" width="100" height="60" />

        <!-- Text placeholders aligned to the right of the image -->
        <rect x="120" y="0" rx="3" ry="3" width="280" height="12" />
        <rect x="120" y="16" rx="3" ry="3" width="260" height="12" />
        <rect
          class="hidden md:visible"
          x="120"
          y="36"
          rx="3"
          ry="3"
          width="280"
          height="6"
        />
        <rect
          class="hidden md:visible"
          x="120"
          y="46"
          rx="3"
          ry="3"
          width="200"
          height="6"
        />
      </template>
    </ContentLoader>
  </Transition>
</template>

<script setup lang="ts">
import { ContentLoader } from 'vue-content-loader'
import type { ContentArticle } from '~/typesManual/content_api/article'

const indexStore = useIndexStore()

const props = withDefaults(
  defineProps<{
    article: ContentArticle
    index: number // The index of the article in the list. Need to differ the first article from the rest
    lazy?: boolean
    preload?: boolean
    sameSize?: boolean // If props.sameSize is true, the articles are displayed in one line and height is the same for all articles.
    mobileSmall?: boolean // If props.mobileSmall is true, the articles are displayed in one line on mobile devices.
  }>(),
  {
    lazy: true,
    preload: false,
    sameSize: false,
    mobileSmall: false,
  }
)

const articleTitle = computed(
  () =>
    props.article.Headline ||
    props.article.HeadLine ||
    props.article.headline ||
    props.article.Title ||
    ''
)

const articleImage = computed(
  () =>
    props.article.ImageUrl ||
    props.article.imageurl ||
    props.article.imageUrl ||
    ''
)

const loaded = computed(() => typeof props.article === 'object')

const type = computed(() => {
  const articleType = props.article.Type ?? props.article.type
  if (articleType != undefined) {
    return getArticleType(articleType)
  } else {
    return 'article'
  }
})

const articleRouteObject = computed(() => {
  return {
    name: indexStore.currentPaperSlug ? 'paper_article' : 'article',
    params: indexStore.currentPaperSlug
      ? {
          paper: indexStore.currentPaperSlug,
          id: props.article.UrlKey || props.article.urlKey,
        }
      : { id: props.article.UrlKey || props.article.urlKey },
  }
})

const isVideo = computed(
  () =>
    /video/.test(type.value) ||
    Boolean(props.article.ToppictHTML?.trim()) ||
    Boolean(props.article.VideoProvider?.trim())
)
</script>
