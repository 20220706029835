<template>
  <div class="flex items-center justify-center">
    <div
      class="rounded-full cursor-pointer bg-red flex items-center justify-center shrink-0 h-8 w-8 md:h-10 md:w-10 ml-2 transition-shadow hover:shadow-[0_0_4px_4px_rgba(255,0,0,0.3)]"
      @click.prevent="toggleAudio"
    >
      <IconPause
        v-if="isPlaying && url === podcastFile"
        id="icon-pause"
        width="18"
        height="18"
      />
      <IconPlay v-else id="icon-play" width="18" height="18" />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Podcast } from '~/typesAuto/apicore/v1'
import type { ContentPodcast } from '~/typesManual/content_api/podcast'

import { ContentArticle } from '~/typesManual/content_api/article'
import IconPlay from '~/assets/icons/play.svg?component'
import IconPause from '~/assets/icons/pause.svg?component'

const dataLayer = useDataLayer()
const siteConfig = useSiteConfig()
const { isPlaying, play, url } = useAudioPlayer()

const props = defineProps<{
  article: ContentArticle
}>()

const podcastFile = computed(() => props.article.Podcast?.file)

const toggleAudio = () => {
  // If playing, stop
  if (isPlaying.value && url.value === podcastFile.value) {
    isPlaying.value = false
  } else {
    playAudio()
  }
}

const isPodcastType = (item: ContentPodcast | Podcast): item is Podcast => {
  return (item as Podcast).recordId !== undefined
}

const playAudio = () => {
  const article = props.article

  const podcast = article.podcast ?? article.Podcast
  if (!podcast) return

  // Track plays
  const headline =
    article.Headline ?? article.HeadLine ?? article.headline ?? ''

  let recordId: string | null | undefined = undefined
  if (isPodcastType(podcast)) {
    recordId = podcast.recordId
  } else {
    recordId = podcast.recordid
  }

  if (recordId) {
    dataLayer.podcast.play(recordId, headline)
  }

  play({
    url: podcastFile.value ?? '',
    imageUrl: `${siteConfig.legacydomain}${podcast.image}`,
    title: headline,
  })
}
</script>
