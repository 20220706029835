<template>
  <div class="flex" v-if="/review/.test(type)">
    <ReviewIcon
      v-for="item in 6"
      :key="item"
      class="w-3 h-3 mr-1px"
      :class="setRatingStyle(item)"
    />
  </div>
  <div
    v-else
    class="font-sans font-bold"
    :class="{
      'text-blue': !props.isFramed,
      'text-white ': props.isFramed,
      'text-xs/3': !props.isFirstArticle,
      'text-xs/3 md:text-sm/3': props.isFirstArticle,
    }"
  >
    <div
      v-if="
        /article|debate|analysis|opinion|politicalSpeech|column|names|kronik|podcast|video/.test(
          props.type
        )
      "
      class="flex items-end"
    >
      <img
        v-if="hasPaywall"
        src="/A_Logomark.svg"
        alt="Altinget logo"
        class="mr-1.5"
        :class="{
          'w-3.5 h-3.5': props.isFirstArticle,
          'w-3 h-23': !props.isFirstArticle,
        }"
      />

      <span :class="{ 'opacity-75': props.isFramed }">
        {{ getLabelContent(type) }}
      </span>
    </div>

    <div v-else>{{ getLabelContent('article') }}</div>
  </div>
</template>

<script setup lang="ts">
import ReviewIcon from '@/assets/icons/rating-small.svg?component'
import { ContentArticle } from '~/typesManual/content_api/article'

const nuxtApp = useNuxtApp()

const props = withDefaults(
  defineProps<{
    article: ContentArticle
    type: string
    isFirstArticle?: boolean
    isFramed?: boolean
    isVideo?: boolean
  }>(),
  {
    isFirstArticle: false,
    isFramed: false,
    isVideo: false,
  }
)

const articleRating = computed(
  () => props.article.rating || props.article.Rating
)

const setRatingStyle = (item: number) => {
  const isFilled = item <= (articleRating.value ?? 0)
  if (props.isFramed) {
    return isFilled ? 'fill-red' : 'fill-white opacity-30'
  } else {
    return isFilled ? 'fill-red' : 'fill-black opacity-10'
  }
}

const hasPaywall = computed(() => props.article.paywall)

const getLabelContent = (type: string) => {
  if (/article/.test(type)) {
    return nuxtApp.$t('News')
  }
  if (/debate/.test(type)) {
    return nuxtApp.$t('Debate')
  }
  if (/analysis/.test(type)) {
    return nuxtApp.$t('Analysis')
  }
  if (/opinion/.test(type)) {
    return nuxtApp.$t('Comment')
  }
  if (/politicalSpeech/.test(type)) {
    return nuxtApp.$t('Speech')
  }
  if (/column/.test(type)) {
    return nuxtApp.$t('Spaltist')
  }
  if (/names/.test(type)) {
    return nuxtApp.$t('NamesNews')
  }
  if (/kronik/.test(type)) {
    return nuxtApp.$t('Chronic')
  }
  if (/podcast/.test(type)) {
    return nuxtApp.$t('Podcast')
  }
  if (/video/.test(type)) {
    return 'Video'
  }
}
</script>
