<template>
  <NuxtLink :to="setArticleRouteObject(props.article.UrlKey)" class="group">
    <B2CTeaserImage
      :imageUrl="props.article.ImageUrl"
      :alt="props.article.Headline"
      :width="850"
      :height="520"
      class="rounded-xl w-full"
    />

    <div class="py-4 md:py-8">
      <B2CArticleTeaserLabel
        type="podcast"
        isFramed
        is-first-article
        :article="props.article"
        class="mb-2"
      />
      <div class="flex overflow-hidden">
        <h2
          class="text-xl/6 md:text-5xl/[56px] font-bold font-sans text-white -tracking-[0.005em] group-hover:underline decoration-2 text-pretty"
          v-if="props.article.Headline"
        >
          {{ props.article.Headline }}
        </h2>
        <div class="pl-6 flex justify-center items-center">
          <B2CPodcastPlayIcon :article="props.article" />
        </div>
      </div>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
import { ContentArticle } from '~/typesManual/content_api/article'

const props = defineProps<{
  article: ContentArticle
}>()
</script>
