export const setArticleRouteObject = (UrlKey?: string | undefined) => {
  const indexStore = useIndexStore()

  return {
    name: indexStore.currentPaperSlug ? 'paper_article' : 'article',
    params: indexStore.currentPaperSlug
      ? {
          paper: indexStore.currentPaperSlug,
          id: UrlKey,
        }
      : { id: UrlKey },
  }
}
