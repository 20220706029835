<template>
  <NuxtLink :to="setArticleRouteObject(props.article.UrlKey)">
    <div class="flex items-center justify-between w-full group">
      <div class="flex">
        <div class="w-28 h-16 grow-0 shrink-0 items-center">
          <B2CTeaserImage
            :imageUrl="props.article.ImageUrl"
            alt=""
            :width="112"
            :height="63"
            class="rounded"
          />
        </div>

        <div class="pl-4 md:pl-6 md:max-w-xl">
          <B2CArticleTeaserLabel
            type="podcast"
            isFramed
            :article="props.article"
            class="mb-1"
          />
          <h2
            class="text-[13px]/4 md:text-lg/6 lg:text-2xl/[30px] font-bold font-sans -tracking-[0.005em] group-hover:underline decoration-1 md:decoration-2 text-pretty pt-2"
            v-if="props.article.Headline"
          >
            {{ props.article.Headline }}
          </h2>
        </div>
      </div>

      <div class="md:pl-6 pl-3">
        <B2CPodcastPlayIcon :article="props.article" />
      </div>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
import { ContentArticle } from '~/typesManual/content_api/article'
const props = defineProps<{
  article: ContentArticle
}>()
</script>
