<template>
  <NuxtLink
    :to="setArticleRouteObject(props.article.UrlKey)"
    class="group flex md:block border-gray-300 rounded-xl border md:p-0 p-3"
  >
    <div class="hidden md:block">
      <B2CTeaserImage
        :imageUrl="props.article.ImageUrl"
        :alt="props.article.Headline"
        :width="420"
        :height="230"
        class="md:w-full md:h-full w-28 h-16 grow-0 shrink-0 rounded-xl md:rounded-b-none"
      />
    </div>
    <NuxtImg
      class="h-20 w-20 md:h-36 md:w-36 rounded object-center md:hidden"
      :src="siteConfig.legacydomain + article.Podcast?.image"
    />

    <div class="w-full md:p-6 ml-3 md:ml-0">
      <B2CArticleTeaserLabel
        type="podcast"
        :article="props.article"
        class="mb-2"
      />
      <div class="flex overflow-hidden justify-between w-full">
        <h2
          class="text-[13px]/4 md:text-2xl/[30px] font-bold font-sans text-black -tracking-[0.005em] group-hover:underline decoration-1 md:decoration-2 text-pretty"
          v-if="props.article.Headline"
        >
          {{ props.article.Headline }}
        </h2>
        <div class="pl-4 flex justify-center items-center">
          <B2CPodcastPlayIcon :article="props.article" />
        </div>
      </div>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
import { ContentArticle } from '~/typesManual/content_api/article'

const siteConfig = useSiteConfig()

const props = defineProps<{
  article: ContentArticle
}>()
</script>
