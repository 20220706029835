<template>
  <template v-if="!hideComponent && articles && articles.length > 0">
    <NuxtLink
      :to="{
        name: 'sponsored_id',
        params: {
          id: article.urlKey ?? article.id,
        },
      }"
      v-for="article in articles"
      class="flex md:flex-col bg-[#2C2CA41A] rounded md:rounded-xl md:overflow-hidden p-3 md:p-0 group border border-gray-300 md:border-none"
    >
      <NuxtImg
        :src="getImageUrlFromArticle(article)"
        :width="460"
        :height="260"
        class="rounded md:rounded-none flex w-28 h-16 md:w-auto md:h-auto grow-0 shrink-0 items-center justify-center"
      />
      <div
        class="w-full md:px-6 md:py-6 ml-3 md:ml-0 font-[Georgia] rounded-xl rounded-t-none md:border md:border-t-0 border-grey-300"
      >
        <span class="text-blue font-bold text-xs/4 flex mb-1"
          >{{ $t('ads.nativeAds1') }} - {{ article.customWriter }}</span
        >
        <h2
          class="text-[13px]/4 md:text-2xl/[30px] font-bold text-black -tracking-[0.005em] group-hover:underline decoration-1 md:decoration-2"
        >
          {{ article.headline }}
        </h2>
      </div>
    </NuxtLink>
  </template>
</template>
<script setup lang="ts">
import type { ArticleDto } from '~/typesAuto/apicore/v1'

const indexStore = useIndexStore()
const nuxtApp = useNuxtApp()

const props = withDefaults(
  defineProps<{
    placementId: string
    article?: ArticleDto
    compact?: boolean
    top?: number
    offset?: number
  }>(),
  {
    top: 1,
    offset: 0,
  }
)

const hideComponent = computed(() => {
  const someTeaserIsInvalid = articles.value?.some(
    (article) => !article.customWriter
  )
  return !props.article?.customWriter && someTeaserIsInvalid
})
const articles = computed(() => {
  return props.article ? [props.article] : data.value?.data
})

const { data } = await useAsyncData(
  `article-sponsored-teaser-top-${props.placementId}-${props.top}-offset-${props.offset}`,
  async () => {
    if (props.article) return
    const articles = await nuxtApp.$api.articles.getSponsoredArticles({
      paperId: indexStore.currentPaperIdentifier,
      $top: props.top + props.offset,
      offset: props.offset,
    })
    return {
      ...articles,
      data: articles.data.slice(0, 1),
    }
  }
)

function getImageUrlFromArticle(article: ArticleDto): string | undefined {
  if (!article.images) {
    return
  }
  return article.images[0]?.url ?? undefined
}
</script>
