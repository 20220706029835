<template>
  <LayoutDkTeaserGrid
    :articles="props.articles.slice(0, 3)"
    :lazy="false"
    :preload-first-image="true"
  />

  <slot></slot>

  <div class="flex flex-col gap-y-6 md:gap-y-8" :class="{ 'mt-8': !hasSlot }">
    <LayoutDkTeaserGridWithSlot
      :articles="props.articles.slice(3, 5)"
      :lazy="false"
      :preload-first-image="true"
    >
      <B2CArticleSponsoredTeaser
        :placement-id="props.sponsoredTeaserPlacementId"
      />
    </LayoutDkTeaserGridWithSlot>

    <LayoutDkTeaserRow :articles="props.articles.slice(5, 8)" />
  </div>
</template>

<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'

const props = withDefaults(
  defineProps<{
    articles: ContentArticle[]
    sponsoredTeaserPlacementId?: string
  }>(),
  {
    sponsoredTeaserPlacementId: '0',
  }
)

const slots = useSlots()
const hasSlot = computed(() => !!slots.default)
</script>
