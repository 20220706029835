<!--
 The LayoutDkTeaserGridWithSlot component takes 2 articles and displays them in a grid reversed view.
 Could take childrens in <slot> to display in grid view.
 Desktop: primary teaser in right column and secondary teasers in left column.
 Mobile: one column layout, primary teaser and secondaries with small image.
-->
<template>
  <div
    v-if="props.articles?.length > 0"
    class="grid grid-cols-2 md:grid-cols-9 gap-y-6 md:gap-x-8"
  >
    <!-- Slot for Sponsored article on mobile -->
    <div v-if="$slots.default" class="col-span-full md:hidden">
      <slot></slot>
    </div>
    <div
      class="col-span-full md:col-span-6 md:row-span-2 md:col-start-4 md:col-end-10"
    >
      <TeaserPrimaryPlacement
        :article="firstArticle"
        :lazy="props.lazy"
        :preload-first-image="props.preloadFirstImage"
        :mobile-small="true"
      />
    </div>

    <div
      class="col-span-full md:col-span-3 md:row-start-1 md:col-start-1 md:col-end-4"
    >
      <div class="grid grid-cols-1 gap-6 md:gap-y-8">
        <div v-for="(article, index) in props.articles.slice(1)">
          <TeaserSecondaryPlacement
            :article="article"
            :index="index"
            :lazy="props.lazy"
            :preload-first-image="props.preloadFirstImage"
          />
        </div>
        <div
          class="hidden"
          :class="{
            'md:block': $slots.default,
          }"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'

const props = withDefaults(
  defineProps<{
    articles: ContentArticle[]
    lazy?: boolean
    preloadFirstImage?: boolean
  }>(),
  {
    lazy: true,
    preloadFirstImage: false,
  }
)

const firstArticle = computed(() => {
  return props.articles[0]
})
</script>
