<!--
 The TeaserSecondaryPlacement component is designed to display an article teaser with a small image.
 Desktop: column layout (image and title under it)
 Mobile: row layout (image on the left, title on the right)
 sameSize prop is used to display articles in one line with the same height.
  Options:
 - Podcast: if type is Podcast, the B2CPodcastTeaser component is displayed
 - Article: if type is Article, the B2CArticleTeaser component is displayed
-->
<template>
  <B2CPodcastTeaserMedium
    v-if="
      props.article.Type && getArticleType(props.article.Type) === 'podcast'
    "
    :article="props.article"
  />
  <B2CArticleTeaser
    v-else-if="
      !props.article.FrontpageTheme &&
      !props.article.FeaturedArticle &&
      !props.article.SponsoredArticle
    "
    :key="'article' + props.index"
    :article="props.article"
    :index="props.index + 1"
    :lazy="props.lazy"
    :preload="props.preloadFirstImage"
    :same-size="props.sameSize"
  />
</template>
<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'

const props = withDefaults(
  defineProps<{
    article: ContentArticle
    index: number
    lazy?: boolean
    preloadFirstImage?: boolean
    sameSize?: boolean
  }>(),
  {
    lazy: true,
    preloadFirstImage: false,
    sameSize: false,
  }
)
</script>
