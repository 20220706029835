<template>
  <Transition name="skeleton" mode="out-in">
    <div v-if="loaded" key="B2Ccontent">
      <!-- First article in a list -->
      <template v-if="props.index === 0">
        <B2CPodcastThemeTeaserLarge
          v-if="type === 'podcast'"
          :article="props.article"
        />
        <NuxtLink v-else :to="articleRouteObject" class="flex flex-col group">
          <B2CArticleTeaserImage
            :article-image="articleImage"
            :article-title="articleTitle"
            is-first-article
            :is-video="isVideo"
            :preload="props.preload"
            :lazy="props.lazy"
            isFramed
          />
          <div class="py-4 md:py-8">
            <B2CArticleTeaserLabel
              class="mb-1 md:mb-3"
              :type="type"
              :article="props.article"
              is-first-article
              isFramed
              :is-video="isVideo"
            />

            <h2
              class="text-xl/6 md:text-5xl/[56px] font-bold font-sans text-white -tracking-[0.005em] group-hover:underline decoration-2 text-pretty"
            >
              {{ articleTitle }}
            </h2>
          </div>
        </NuxtLink>
      </template>
      <!-- Not first article in a list -->
      <template v-else>
        <B2CPodcastThemeTeaserSmall
          v-if="type === 'podcast'"
          :article="props.article"
        />
        <NuxtLink v-else :to="articleRouteObject" class="flex group">
          <B2CArticleTeaserImage
            :article-image="articleImage"
            :article-title="articleTitle"
            :is-video="isVideo"
            :preload="props.preload"
            :lazy="props.lazy"
            isFramed
            row-layout
          />
          <div class="pl-4 md:pl-6 md:max-w-xl">
            <B2CArticleTeaserLabel
              :type="type"
              :article="article"
              isFramed
              :is-video="isVideo"
            />

            <h2
              class="text-[13px]/4 md:text-lg/6 lg:text-2xl/[30px] font-bold font-sans -tracking-[0.005em] group-hover:underline decoration-1 md:decoration-2 text-pretty pt-2"
            >
              {{ articleTitle }}
            </h2>
          </div>
        </NuxtLink>
      </template>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'

const indexStore = useIndexStore()

const props = withDefaults(
  defineProps<{
    article: ContentArticle
    index: number // The index of the article in the list. Need to differ the first article from the rest
    lazy?: boolean
    preload?: boolean
  }>(),
  {
    lazy: true,
    preload: false,
  }
)

const articleTitle = computed(
  () =>
    props.article.Headline ||
    props.article.HeadLine ||
    props.article.headline ||
    props.article.Title ||
    ''
)

const articleImage = computed(
  () =>
    props.article.ImageUrl ||
    props.article.imageurl ||
    props.article.imageUrl ||
    ''
)

const loaded = computed(() => typeof props.article === 'object')

const type = computed(() => {
  const articleType = props.article.Type ?? props.article.type
  if (articleType != undefined) {
    return getArticleType(articleType)
  } else {
    return 'article'
  }
})

const articleRouteObject = computed(() => {
  return {
    name: indexStore.currentPaperSlug ? 'paper_article' : 'article',
    params: indexStore.currentPaperSlug
      ? {
          paper: indexStore.currentPaperSlug,
          id: props.article.UrlKey || props.article.urlKey,
        }
      : { id: props.article.UrlKey || props.article.urlKey },
  }
})

const isVideo = computed(
  () =>
    /video/.test(type.value) ||
    Boolean(props.article.ToppictHTML?.trim()) ||
    Boolean(props.article.VideoProvider?.trim())
)
</script>
