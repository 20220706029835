import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M8.571 0A1.43 1.43 0 0 1 10 1.429V8.57A1.43 1.43 0 0 1 8.571 10H1.43A1.43 1.43 0 0 1 0 8.571V1.43A1.43 1.43 0 0 1 1.429 0zM5 1.959l-.257.001c-.966.016-1.729.16-2.195 1.294C2.084 4.386 1.4 8.025 1.4 8.025h.893a.59.59 0 0 0 .55-.388l.1-.346.024-.077c.219-.605.979-.967 2.033-.967 1.055 0 1.814.362 2.033.967l.024.077c.026.086.064.22.099.346a.59.59 0 0 0 .552.388H8.6l-.245-1.23c-.241-1.164-.614-2.837-.903-3.54C6.945 2.02 6.088 1.958 5 1.958m0 .917c.581 0 .979.088 1.149.437l.059.131c.161.392.284.957.552 2.112l-.196-.052A7 7 0 0 0 5 5.308a7 7 0 0 0-1.76.248L3.51 4.4c.126-.521.219-.836.341-1.087.17-.349.568-.437 1.149-.437",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }