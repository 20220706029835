<!--
 The LayoutDkTeaserGrid component takes 3 articles and displays them in a grid view. 
 Desktop: primary teaser in left column and secondary teasers in right column.
 Mobile: one column layout, primary teaser is always with a big image, secondaries with small image.
-->
<template>
  <div
    v-if="props.articles?.length > 0"
    class="grid grid-cols-2 md:grid-cols-9 gap-y-6 md:gap-x-8"
  >
    <div
      class="col-span-full md:col-span-6 md:row-span-2 md:col-start-1 md:col-end-7"
    >
      <TeaserPrimaryPlacement
        :article="firstArticle"
        :lazy="props.lazy"
        :preload-first-image="props.preloadFirstImage"
      />
    </div>

    <div
      class="col-span-full md:col-span-3 md:row-start-1 md:col-start-7 md:col-end-10"
    >
      <div class="grid grid-cols-1 gap-6 md:gap-y-8">
        <div v-for="(article, index) in props.articles.slice(1)">
          <TeaserSecondaryPlacement
            :article="article"
            :index="index"
            :lazy="props.lazy"
            :preload-first-image="props.preloadFirstImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'

const props = withDefaults(
  defineProps<{
    articles: ContentArticle[]
    lazy?: boolean
    preloadFirstImage: boolean
  }>(),
  {
    lazy: true,
    preloadFirstImage: false,
  }
)

const firstArticle = computed(() => {
  return props.articles[0]
})
</script>
